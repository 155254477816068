import { Ref, ref } from 'vue';
import { NavigationGuard } from 'vue-router';
import configHelper from '../helpers/configHelper';
import { AuthResponse } from '../types';
import { useFetch } from './useFetch';

interface useAuthentactionReturn {
  /** Vue router navigation guard to protect a route. Use on `beforeEnter` of desired route. */
  requireAuth: NavigationGuard;
  /** True, if the auth status has been checked */
  isChecked: Ref<boolean>;
  /** Trigger a check of current users authentication */
  checkAuth: () => Promise<void>;
  /** True while the authentication status is being checked */
  isLoading: Ref<boolean>;
  /** True, if the current user is logged in */
  isLoggedIn: Ref<boolean>;
  /** True, if the current user is a staff member  */
  isStaff: Ref<boolean>;
}

const isLoggedIn = ref(false);
const isStaff = ref(false);
const isChecked = ref(false);
const { fetchApi, isLoading } = useFetch();

export const useAuthentication = (): useAuthentactionReturn => {
  /**
   * Trigger a check of current users authentication
   */
  const checkAuth = async () => {
    try {
      const response = await fetchApi<AuthResponse>(configHelper.AUTH_URL, {
        useCredentials: true,
      });

      isLoggedIn.value = response.logged_in;
      isStaff.value = response.is_staff;
    } catch (error) {
      console.log(error);
    } finally {
      isChecked.value = true;
    }
  };

  /**
   * Vue router navigation guard to protect a route.
   * Use on `beforeEnter` of desired route.
   */
  const requireAuth: NavigationGuard = async (to, from, next) => {
    if (!isChecked.value) await checkAuth();

    if (isLoggedIn.value && isStaff.value) return next();

    return next('/401');
  };

  return { requireAuth, isChecked, checkAuth, isLoading, isLoggedIn, isStaff };
};

export default useAuthentication;
